export default function ModalCompleted() {
  return (
    <div className="modal-wrapper">
        <h1>BEDANKT!</h1>
        <p className="my-4 pb-3">
        Hartelijk dank voor je aanmelding!
        Dank je wel voor je interesse in FUNE en voor het delen van je e-mailadres. We waarderen het enorm dat je ervoor hebt gekozen om op de hoogte te blijven van de laatste ontwikkelingen.  
        </p>
   
        <p>We begrijpen dat je uitkijkt naar meer informatie over ons product, en we willen je laten weten dat we hard werken om iets geweldigs te creëren. Jouw aanmelding motiveert ons en we zijn enthousiast om je op de hoogte te houden van de voortgang.

        </p>
        Als geregistreerde insider krijg je exclusieve toegang tot updates, nieuwsbrieven en mogelijk zelfs voorproefjes van functies. We willen dat je het gevoel hebt deel uit te maken van deze reis, dus aarzel niet om vragen te stellen of feedback te geven.
        Nogmaals bedankt voor je steun. We kunnen niet wachten om je meer te vertellen over FUNE
        <p></p>
        <p>Met vriendelijke groet, FUNE</p>

        <p> Heb je vragen of opmerkingen? Neem dan gerust contact op met ons via <a href="mailto:info@fune.nu">info@fune.nu</a> </p>
     </div>
  )
}
