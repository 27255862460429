import React from 'react';
import { useFormFields, useMailChimpForm } from 'use-mailchimp-form';
import PropTypes from 'prop-types';

export default function Modal({ onSuccess }) {
  const actionURL = `https://fune.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}&v_id=${process.env.REACT_APP_MAILCHIMP_V_ID}&f_id=${process.env.REACT_APP_MAILCHIMP_F_ID}`;
  
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(actionURL);
  const {fields, handleFieldChange} = useFormFields({
    EMAIL: ""
  });

  // Effect to call onSuccess when the form is successfully submitted
  React.useEffect(() => {
    if (success) {
      onSuccess();
    }
  }, [success, onSuccess]);

  return (
    <div id="formmodal" className="modal-wrapper">
      <h1>Blijf op de hoogte</h1>
      <p style={{textAlign:'left'}}className="my-4 pb-3">
        Wij zijn verheugd dat je deel wilt uitmaken van onze groeiende community en als een van de eersten op de hoogte wilt zijn van de laatste ontwikkelingen. 
        Schrijf je in voor onze nieuwsbrief en ontvang exclusieve updates, sneak peeks, en speciale aanbiedingen direct in je inbox.
      </p>
      <h5 style={{textAlign:"left"}}>Waarom je moet inschrijven:</h5>
      <ul style={{textAlign:"left"}} className="my-4 pb-3">
        <li>Primeur: Wees de eerste die nieuwe functies, lanceringen en aankondigingen ontdekt.</li>
        <li>Exclusieve Toegang:Als abonnee krijg je mogelijk toegang tot speciale deals en voorproefjes.</li>
        <li>Betrekking bij de Ontwikkeling: Jouw feedback is belangrijk voor ons! Help ons vorm te geven aan het product en de diensten.</li>
      </ul>
      <form
        onSubmit={event => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
        <input
          id="EMAIL"
          autoFocus
          type="email"
          name="EMAIL"
          value={fields.EMAIL}
          onChange={handleFieldChange}
          placeholder="Email"
          required
          className="d-block w-100"
        />
        <button type="submit" disabled={loading} className="btn-1">Inschrijven</button>
      </form>
      {loading && <p>Versturen...</p>}
      {error && <p>{message}</p>}
      {!loading && success && <p>{message}</p>}
      <p style={{textAlign:'left'}}className="mt-4">Privacy belofte: Wij respecteren je privacy en zullen je gegevens nooit delen met derden. Je kunt je op elk moment uitschrijven.</p>
      <p style={{textAlign:'left'}}> Heb je vragen of opmerkingen? Neem dan gerust contact op met ons via <a href="mailto:info@fune.nu">info@fune.nu</a> </p>
    </div>
  );
}

Modal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
